<mat-tab-group>
	<mat-tab label="Hole Count & Length" >
		<ng-template matTabContent>
			<app-hole-count-and-length></app-hole-count-and-length>
		</ng-template>
	</mat-tab>
	<mat-tab label="Burden & Spacing Chart View">
		<ng-template matTabContent>
			<app-burden-and-spacing></app-burden-and-spacing>
		</ng-template>
	</mat-tab>
	<mat-tab label="Drill Length & Diameter">
		<ng-template matTabContent>
			<app-drill-length-and-diameter></app-drill-length-and-diameter>
		</ng-template>
	</mat-tab>
	<mat-tab label="Loading Design">
		<ng-template matTabContent>
			<app-loading-design></app-loading-design>
		</ng-template>
	</mat-tab>
	<mat-tab label="Burden & Spacing Table View">
		<ng-template matTabContent>
			<app-burden-and-spacing-table></app-burden-and-spacing-table>
		</ng-template>
	</mat-tab>
</mat-tab-group>
