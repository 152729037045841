import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { SiteService } from '@app/app-state/site.service';

@Component({
  selector: 'app-drill-and-blast-dashboard',
  templateUrl: './drill-and-blast-dashboard.component.html',
  styleUrls: ['./drill-and-blast-dashboard.component.scss']
})

export class DrillAndBlastDashboardComponent implements OnInit {

  constructor(private readonly siteService: SiteService) {}

  ngOnInit(): void {}
}
