import { Component, Input, OnInit } from '@angular/core';
import { SiteService } from '@app/app-state/site.service';

@Component({
	selector: 'app-fragmentation-dashboard',
	templateUrl: './fragmentation-dashboard.component.html',
	styleUrls: ['./fragmentation-dashboard.component.scss']
})
export class FragmentationDashboardComponent {

	constructor(private readonly siteService: SiteService) {}
}
