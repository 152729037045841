import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SiteService } from '@app/app-state/site.service';
import { ChartDataService } from '@app/backend/chart-data.service';
import { FielderDataset } from '@app/models/chart-data.model';

@Component({
	selector: 'app-fielder-dashboard',
	templateUrl: './fielder-dashboard.component.html',
	styleUrls: ['./fielder-dashboard.component.scss']
})
export class FielderDashboardComponent implements OnInit {
	@Input() siteId:number;
	@Input() domainId:number;

	public drillingDataOptions: any;
	public selectedDrillingId: string;
	public drillingControl =  new FormControl<FielderDataset>(null);
	public subdomainId:number;

	constructor(
		private readonly siteService: SiteService,
		private readonly chartDataService: ChartDataService) {
	}

	ngOnInit(): void {
		this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			if(this.subdomainId ===  subdomainId) {
				if (this.selectedDrillingId) {
					this.selectedDrillingId = null;
					this.drillingControl.setValue(null);
					this.siteService.selectedDrillMachine$.next(this.selectedDrillingId);
				}
			}
		});

		if(this.subdomainId) {
			this.chartDataService.getDrillMachines(this.subdomainId);
		}
		
		this.chartDataService.allFielderDataset$.subscribe((drillingData) => {
			this.drillingDataOptions = drillingData;
			if(drillingData == null) {
				this.drillingControl.setValue(null);
			}
		});
	}

	displayDrillingName(drillingObj: FielderDataset): string {
		return drillingObj && drillingObj.name ? drillingObj.name : '';
	}

	onDrillingInput(event): void {
		if (!event?.data) {
			this.selectedDrillingId = null;
			this.siteService.selectedDrillMachine$.next(this.selectedDrillingId);
		}
	}

	onDrillingSelected(event: any): void {
		const selectedDrillingValue = event.option.value as FielderDataset;
		this.drillingControl.setValue(selectedDrillingValue);
		this.siteService.selectedDrillMachine$.next(selectedDrillingValue?.uuid);
		this.selectedDrillingId = selectedDrillingValue?.uuid;
	}
}
