import { Component, OnInit } from '@angular/core';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, FielderAnalyticsInterface } from "@app/models/chart-data.model";
import * as Highcharts from 'highcharts/highstock';

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-fielder-analytics-by-drill',
	templateUrl: './fielder-analytics-by-drill.component.html',
	styleUrls: ['./fielder-analytics-by-drill.component.scss']
})
export class FielderAnalyticsByDrillComponent implements OnInit {

	public Highcharts: typeof Highcharts = Highcharts; // required

	public chartOptions = [];
	private subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string
	private fielderAnalytics:ChartDataInterface[] = [];
	
	constructor(
		private chartDataService:ChartDataService,
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService,
		private userService: UserService) {}

	ngOnInit() {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));
		
		this.subscriptions.add(this.chartDataService.fielderAnalyticsByDrill$.subscribe((res) => {
			this.triggerLoadData(res);
		}));
		
		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.getReport();
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.selectedDrillMachine$.subscribe(() => {
			this.chartDataService.getAllFielderAnalyticsByDrill();
		}));
	}

	private async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheFielderAnalyticsByDrill().subscribe((res) => {
			this.triggerLoadData(res);
		}));
	}

	private loadData = (chartData: ChartDataInterface[])=> {
		this.fielderAnalytics = chartData;
		const data:FielderAnalyticsInterface = this.generateChartDataService.generateChartData(this.fielderAnalytics);
		for (const key in data?.drillGraph) {
			this.chartOptions = charts.generateChartOptions(data.drillGraph[key]);
		}
	}

	private triggerLoadData(data? : ChartDataInterface[]) {

		const originalFielderAnalyticsByDrill = data || this.generateChartDataService.originalFielderAnalyticsByDrill;
		this.fielderAnalytics = this.generateChartDataService.getFilteredData(
			originalFielderAnalyticsByDrill,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline	
		);
		this.loadData(this.fielderAnalytics);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
