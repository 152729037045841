<ng-container *ngIf="!loading">
	<div class="highchart-wrapper">
		<mat-grid-list cols="2" rowHeight="2:1">
			<mat-grid-tile *ngFor="let chartOption of chartOptions; let i = index">
				<highcharts-chart
					id="chart-container-{{ i }}"
					[Highcharts]="Highcharts"
					[options]="chartOption">
				</highcharts-chart>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
</ng-container>
  
<ng-container *ngIf="loading">
	<div class="loading-logo">
		<mat-spinner></mat-spinner>
	</div>
</ng-container>