export const DrillAndBlastDataStructure = {
	loadingDesign: {
		loadingDesign: [
			{ dataField: 'totalExplosiveWeight', chartType: 'column', chartTitle: 'Total Explosive Quantity', yAxisLabel: 'Total Explosive', xAxisLabel: 'Project Names', isExplosiveGraph: true, valueUnit: 'largeWeight' },
			{ dataField: '', chartType: 'column', chartTitle: 'Truck Explosive', dataFieldTwo: '', seriesType: 'column', isTruckGraph: true, yAxisLabel: 'Explosive Amount', xAxisLabel: 'Truck Details', valueUnit: 'largeWeight' },
			{ dataField: 'averageExplosive', chartType: 'line', chartTitle: 'Average Explosive', yAxisLabel: 'Average Explosive', xAxisLabel: 'Project Names', valueUnit: 'weight' },
			{ dataField: 'averageExplosiveLength', chartType: 'line', chartTitle: 'Average Explosive Length', yAxisLabel: 'Average Explosive Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averageStemmingLength', chartType: 'line', chartTitle: 'Average Stemming', yAxisLabel: 'Average Stemming', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'MCPD/MIC', chartType: 'column', chartTitle: 'MCPD/MIC', yAxisLabel: 'MCPD/MIC', xAxisLabel: 'Project Names', valueUnit: 'smallTimePerDistance' },
			{ dataField: 'averageBurdenRelief', chartType: 'line', chartTitle: 'Average Burden Relief', yAxisLabel: 'Average Burden Relief', xAxisLabel: 'Project Names', valueUnit: 'smallTimePerDistance' }
		]
	},
	burdenAndSpacing: {
		burdenSpacinGraph: [
			{ dataField: 'averageSpacing', chartType: 'column', chartTitle: 'Average Spacing', yAxisLabel: 'Average Spacing', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averageFaceBurden', chartType: 'column', chartTitle: 'Average Face Burden', yAxisLabel: 'Average Face Burden', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averagePatternBurden', chartType: 'line', chartTitle: 'Average Pattern Burden', dataFieldTwo:'designBurden', seriesType:'line', seriesType1:'line', yAxisLabel: 'Average Pattern Burden', xAxisLabel: 'Project Names', valueUnit: 'distance', isCombineType:true, isDesignBurdenGraph:true },
		]
	},
	drillLengthAndDiameter: {
		drillDiameterGraph: [
			{ dataField: 'totalDrillLength', chartType: 'line', chartTitle: 'Total Drilling Length', yAxisLabel: 'Total Drilling Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averageHoleDiameter', chartType: 'column', chartTitle: 'Average Hole Diameter', yAxisLabel: 'Hole Diameter', xAxisLabel: 'Project Names', valueUnit: 'smallDistance' },
		]
	},
	holeCountAndLength: {
		holeCountGraph: [
			{ dataField: 'holeCount', chartType: 'column', chartTitle: 'Number Of Holes', yAxisLabel: 'Hole Count', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'averageHoleLength', chartType: 'line', chartTitle: 'Average Hole Length', yAxisLabel: 'Average Hole Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: '', chartType: 'column', chartTitle: 'Average Laborers, Average Hours', dataFieldTwo: '', seriesType: 'column', seriesType1: 'column', isCombineType: true, isLabourGraph: true, yAxisLabel: 'Average Laborers, Average Hours', xAxisLabel: 'Site Names', valueUnit: 'count' },
		],
	},
	pfAndTonData: {
		pfTonGraph: [
			{ dataField: 'blastVolume', chartType: 'column', chartTitle: 'Blast Volume', yAxisLabel: 'Blast Volume', xAxisLabel: 'Project Names', valueUnit: 'volume' },
			{ dataField: 'blastTonnage', chartType: 'column', chartTitle: 'Blast Tonnage', yAxisLabel: 'Blast Tonnage', xAxisLabel: 'Project Names', valueUnit: 'largeWeight' },
			{ dataField: 'powderFactorVolume', chartType: 'column', chartTitle: 'Powder Factor Volume', yAxisLabel: 'Power Factor Volume', xAxisLabel: 'Project Names', valueUnit: 'powderFactorVolume' },
			{ dataField: 'powderFactorWeight', chartType: 'column', chartTitle: 'Powder Factor Weight', yAxisLabel: 'Powder Factor Weight', xAxisLabel: 'Project Names', valueUnit: 'powderFactorWeight' },
			{ dataField: 'productionPerHour', chartType: 'column', chartTitle: 'Production Per Hour', yAxisLabel: 'Production Per Hour', xAxisLabel: 'Project Names', valueUnit: 'volume' },
			{ dataField: 'drillYield', chartType: 'column', chartTitle: 'Drill Yield/specific drilling', yAxisLabel: 'Drill Yield/specific drilling', xAxisLabel: 'Project Names', valueUnit: 'volume' },
		]
	},
};

export const FielderAnalysisDataStructure = {
	fielderAnalyticsData: {
		fielderAnalyticsDataGraph: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Length', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time', yAxisLabel: 'Drilling Time', xAxisLabel: 'Project Names', valueUnit: 'hour' },
			{ dataField: 'averageTimeBetweenHoles', chartType: 'column', chartTitle: 'Average Time Between Holes', yAxisLabel: 'Average Time Between Holes', xAxisLabel: 'Project Names', valueUnit: 'minute' },
			{ dataField: 'averageTimePerHole', chartType: 'line', chartTitle: 'Average Time Per Hole', yAxisLabel: 'Average Time Per Hole', xAxisLabel: 'Project Names', valueUnit: 'minute' },
			{ dataField: 'averagePerDayDrill', chartType: 'column', chartTitle: 'Average Drilled Per Day', yAxisLabel: 'Average Per Day Drill', xAxisLabel: 'Project Names', valueUnit: 'distance' },
		]
	},
	utilizationByShot: {
		utilizationByShotGraph: [
			{ dataField: 'utilization', chartType: 'column', chartTitle: 'Utilization', yAxisLabel: 'Utilization', xAxisLabel: 'Project Names', valueUnit: 'percent' },
		],
	},
	drilledHolesByDrilledLength: {
		drilledHolesByDrilledLengthGraph: [
			{ dataField: 'totalHoles', chartType: 'zoomType', chartTitle: 'Total Holes, Total Drilled Holes', yAxisLabel: 'Total Holes, Total Drilled Holes', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', seriesType: 'line', seriesType1: 'column', isCombineType: true, valueUnit: 'count' },
		],
	},
	totalHolesAndTotalDrilledHolesByShot: {
		totalHolesAndTotalDrilledHolesByShotGraph: [
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Holes, Total Drilled Length', yAxisLabel: 'Total Drilled Holes, Total Drilled Length', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', isCombineType: true, valueUnit: 'count' },
		]
	},
	drillGraph: {
		drillGraphData: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes By Drill Machine Name', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilling Length By Drill Machine Name', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time By Drill Machine Name', yAxisLabel: 'Total Drilled Time', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'hour' },
		]
	}
};

export const FragmentationStructure = {
	fragmentationGraphData: {
		fragmentationGraph: [
			{ dataField: 'd50', chartType: 'column', chartTitle: 'D50, D80', dataFieldTwo: 'd80', seriesType: 'column', seriesType1: 'column', isCombineType: true, yAxisLabel: 'D50, D80', xAxisLabel: 'Project Names', valueUnit: 'smallDistance' },
			{ dataField: 'fragmentationSizeRange', chartType: 'column', chartTitle: 'Fragmentation Size Ranges Chart', isSizeRange: true, yAxisLabel: '%passing', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'finesPercent', chartType: 'line', chartTitle: 'Fines Percent', isFinesPercent: true, yAxisLabel: 'Fines Percent', xAxisLabel: 'Project Names',  valueUnit: 'count' },
			{ dataField: 'oversizePercent', chartType: 'column', chartTitle: 'Oversize Percent', isSizeRange: true, isOversizePercent: true, yAxisLabel: 'Oversize Percent', xAxisLabel: 'Project Names',  valueUnit: 'count' },
			{ dataField: 'groundVibrationPPV', chartType: 'line', chartTitle: 'Ground Vibration PPV', yAxisLabel: 'Ground Vibration PPV', xAxisLabel: 'Project Names', valueUnit:  'smallVelocity' },
		]
	},
	muckpile: {
		muckpile: [
			{ dataField: 'swellFactor', chartType: 'line', chartTitle: 'Swell Factor', yAxisLabel: 'Swell Factor', xAxisLabel: 'Project Names',  valueUnit: 'count' },
			{ dataField: 'averageMuckpileVolume', chartType: 'line', chartTitle: 'Muckpile Volume', yAxisLabel: 'Muckpile Volume', xAxisLabel: 'Project Names', valueUnit:  'volume' },
			{ dataField: 'averageMuckpileTonnage', chartType: 'line', chartTitle: 'Muckpile Tonnage', yAxisLabel: 'Muckpile Tonnage', xAxisLabel: 'Project Names', valueUnit:  'largeWeight' },
		]
	}
};

export const DrillAndBlastCostsStructure = {
	drillAndBlastCostsGraphData: {
		drillAndBlastCostsGraph: [
			{ dataField: 'tonsPerHour', chartType: 'bar', chartTitle: 'Tons per Hour by Sites', dataFieldTwo: 'tonsPerHourGoal', seriesType: 'bar', seriesType1: 'bar', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Tons per Hour', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'finesPercent', chartType: 'area', chartTitle: 'Fines Percent by Sites', dataFieldTwo: 'finesPercentGoal', seriesType: 'area', seriesType1: 'area', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Fines Percent', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'oversizePercent', chartType: 'area', chartTitle: 'Oversize Percent by Sites', dataFieldTwo: 'oversizePercentGoal', seriesType: 'area', seriesType1: 'area', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Oversize Percent', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'explosivesCostPerTon', chartType: 'column', chartTitle: 'Explosives Cost Per Ton by Sites', dataFieldTwo: 'explosivesCostPerTonGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Explosives Cost Per Ton', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'drillingCostPerTon', chartType: 'bar', chartTitle: 'Drilling Cost Per Ton by Sites', dataFieldTwo: 'drillingCostPerTonGoal', seriesType: 'bar', seriesType1: 'bar', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Drilling Cost Per Ton', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'drillAndBlastCostPerTon', chartType: 'column', chartTitle: 'Drill And Blast Cost Per Ton by Sites', dataFieldTwo: 'drillAndBlastCostPerTonGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Drill And Blast Cost Per Ton', xAxisLabel: 'Sites', valueUnit: 'count' },
			{ dataField: 'secondaryBreakageCost', chartType: 'area', chartTitle: 'Secondary Breakage Cost by Sites', dataFieldTwo: 'secondaryBreakageCostGoal', seriesType: 'area', seriesType1: 'area', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Secondary Breakage Cost', xAxisLabel: 'Sites', valueUnit: 'count' },
		]
	}
};
