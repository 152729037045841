import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UserService } from '@app/app-state/user.service';
import { updatedTypeMeta } from '@app/dashboard-measurement/types';
import { ChartDataInterface, Series } from '@app/models/chart-data.model';
import { User } from '@app/models/user.model';
import { measurement } from '@lib/measurement';
import { Unit } from '@lib/measurement/units';
import { BehaviorSubject } from 'rxjs';
import { SiteService } from '../app-state/site.service';
import { DrillAndBlastCostsStructure, DrillAndBlastDataStructure, FielderAnalysisDataStructure, FragmentationStructure } from './data-structures';

@Injectable({
	providedIn: 'root'
})


export class GenerateChartDataService {
        
	public drillAndBlastDataStructure = DrillAndBlastDataStructure;
	public fielderAnalysisDataStructure = FielderAnalysisDataStructure;
	public fragmentationStructure = FragmentationStructure;
	public drillAndBlastCostsStructure = DrillAndBlastCostsStructure;

	public originalDrillAndBalstData: ChartDataInterface[] =[];
	public originalFielderAnalytics:ChartDataInterface[] = [];
	public originalFragmentationData:ChartDataInterface[] = [];
	public originalFielderAnalyticsByDrill:ChartDataInterface[]= [];
	public filteredData: ChartDataInterface[] = [];

	private siteId :number;
	private userUnitMeasurement:string;
	
	private chartOptionsSubject = new BehaviorSubject<any>(null);
	chartOptions$ = this.chartOptionsSubject.asObservable();
	
	constructor(
		private siteService:SiteService, 
		private datePipe: DatePipe,
		private userService: UserService,
		private user: User) {
		this.siteService.siteId$.subscribe(siteId => this.siteId = siteId);   
		this.userService.userUnitMeasurement$.subscribe(
			measurementUnit => this.userUnitMeasurement = measurementUnit
		)
	}

	public getFilteredData(originalData, domainId: number, subdomainId: number, siteId: number, startDate:string, endDate: string, selectedTimeline: string) {
		return originalData?.filter(data => {
			let matches = true;
			if (domainId) {
				matches = matches && data.domainId === domainId;
			}

			if (subdomainId) {
				matches = matches && data.subdomainId === subdomainId;
			}
	
			if (siteId) {
				matches = matches && data.siteId === siteId;
			}
	
			if (startDate) {
				let formattedDate = this.datePipe.transform(data.datasetCreatedAt ? data.datasetCreatedAt : data.createdAt, 'yyyy-MM-dd', 'UTC');
				matches = matches && formattedDate >= startDate;
			}
	
			if (endDate) {
				let formattedDate = this.datePipe.transform(data.datasetCreatedAt ? data.datasetCreatedAt : data.createdAt, 'yyyy-MM-dd', 'UTC');
				matches = matches && formattedDate >= startDate && formattedDate <= endDate;
			}

			if(selectedTimeline){
				const timelineDate = this.processTimelineDate(selectedTimeline);
				let formattedDate = this.datePipe.transform(data.datasetCreatedAt ? data.datasetCreatedAt : data.createdAt, 'yyyy-MM-dd', 'UTC');
				matches = matches && formattedDate >= timelineDate;
			}
			return matches;
		});
	}
	

	private getDataStructure() {
		switch (this.siteService.activeSideNav) {
			case 'drillAndBlast':
				return this.drillAndBlastDataStructure;
			
			case 'fielder':
				return this.fielderAnalysisDataStructure;
			
			case 'fragmentation': 
				return this.fragmentationStructure;

			case 'drillAndBlastCosts': 
				return this.drillAndBlastCostsStructure;	

			default:
				return null;
		}   
	}    

	public generateChartData(response, isPfAndTon?: boolean) {
		const dataStructure = isPfAndTon ? this.drillAndBlastDataStructure : this.getDataStructure();
		const chartOptionsObject = {};
	  
		if (!dataStructure) {
		  return chartOptionsObject;
		}
	  
		Object.keys(dataStructure).forEach(sectionKey => {
		  chartOptionsObject[sectionKey] = {};
	  
		  const sectionData = dataStructure[sectionKey];
	  
		  Object.keys(sectionData).forEach(graphKey => {
			chartOptionsObject[sectionKey][graphKey] = [];
	  
			const graphConfigurations = sectionData[graphKey];
			graphConfigurations.forEach(config => {
				const { dataField, chartType, chartTitle, yAxisLabel, xAxisLabel, dataFieldTwo, seriesType, seriesType1, isCombineType, isDrillAndBlastCosts, isLabourGraph, isSizeRange, isOversizePercent, isFinesPercent, isTruckGraph, isDrillName, isDesignBurdenGraph, valueUnit} = config;
				let metricUnit:Unit = updatedTypeMeta[valueUnit].metricUnit;
				let imperialUnit:Unit = updatedTypeMeta[valueUnit].imperialUnit;
                
			  if (response) {
				const chartOptions = this.getChartOptions(dataField, chartType, chartTitle, response, yAxisLabel, xAxisLabel, imperialUnit,metricUnit, this.userService.user.unit(valueUnit),dataFieldTwo, seriesType, seriesType1, isDesignBurdenGraph,isCombineType, isDrillAndBlastCosts, isLabourGraph, isSizeRange, isOversizePercent, isFinesPercent, isTruckGraph, isDrillName);
				chartOptionsObject[sectionKey][graphKey].push(chartOptions);
				
				const graphKeyConditions = {
				  'holeCountGraph': 4,
				  'pfTonGraph': 6
				};

				if (graphKeyConditions.hasOwnProperty(graphKey) && chartOptionsObject[sectionKey][graphKey].length === graphKeyConditions[graphKey] && !this.siteId) {
				  chartOptionsObject[sectionKey][graphKey].splice(-1, 1);
				}

				if (this.siteService.activeSideNav === 'drillAndBlast' && graphKey === 'burdenSpacinGraph') {
					
					const burdenGraphOption = [];
					burdenGraphOption.push(chartOptions);
					
					this.chartOptionsSubject.next(burdenGraphOption);
				}
				
			  }
			});
		  });
		  
		});
	  
		return chartOptionsObject;
	}
	  
	private getChartOptions(label1: string, chartType: string | null, chartTitle: string, response: Array<any>, yAxisLabel: string, xAxisLabel: string, imperialUnit: Unit, metricUnit: Unit, unit: measurement.Unit, label2?: string | null, seriesType?: string, seriesType1?: string | null, isDesignBurdenGraph?: boolean, isCombineType?: boolean | false, isDrillAndBlastCosts?: boolean, isLabourGraph?: boolean, isSizeRange?: boolean, isOversizePercent?: boolean, isFinesPercent?: boolean, isTruckGraph?: boolean, isDrillName?: boolean, isExplosiveGraph?: boolean) {
		let datasetName:string[];
		
		const unitAbbreviation = measurement.abbreviation(unit);
		let toUnit:Unit = this.userUnitMeasurement === 'imperial' ? imperialUnit : metricUnit;
		const processCost = (cost: number, label: string) => {
			const convertedValue = measurement.convert(Number(cost[label]), toUnit) 	
			return Math.round(convertedValue * 100) / 100;
		};
		
		const original = response?.map(cost => processCost(cost, label1));
		const combineTypeSeries = response?.map(cost => processCost(cost, label2));
		

		if(isDrillName) {
			datasetName = response?.map(cost => cost.drillMachineName);
		} else {
			datasetName = response?.map(cost => cost.datasetName);
		}

		let seriesData :Series[] = [];
		const uniqueSites = {};

		switch (true) {
			case isTruckGraph:
				const truckGraph = this.generateTruckGraphSeriesData(response);
				let data = Object.values(truckGraph).flat().map(res => Number(res["explosiveAmount"]));
				datasetName = Object.keys(truckGraph);
				data = data.map(cost => {
					const converted = measurement.convert(cost, toUnit);
					return Math.round(converted * 100) / 100;
				});
				seriesData = [
					{ name: yAxisLabel, data },
				];
				break;
			
			case this.siteId && isExplosiveGraph:
				seriesData = [
					{ name: yAxisLabel, data: original },
				];
				break;

			case isSizeRange && isOversizePercent:
				response = response?.filter(data => Object?.keys(data?.fragmentationSizeRanges)?.length > 0);
				datasetName = response?.map(cost => cost.datasetName);
				seriesData = [{name: yAxisLabel ,data: response?.map((dataset)=>{return Number(measurement.toMaxDecimals(dataset.fragmentationSizeRanges.rocksAreaPercent[dataset.fragmentationSizeRanges.rocksAreaPercent?.length - 1], 2))})}]
				break;

			case isSizeRange:
				response = response?.filter(data => Object?.keys(data?.fragmentationSizeRanges)?.length > 0);
				datasetName = response?.map(cost => cost.datasetName);
				seriesData = this.generateSizeRangeSeriesData(response);
				break;
		
			case isCombineType && isLabourGraph:
				response.forEach(item => {
					if (!(item.siteId in uniqueSites)) {
						uniqueSites[item.siteId] = item.siteName;
					}
				});
				datasetName = Object.values(uniqueSites);
				seriesData = this.generateLabourAndHourSeriesData(response);
				break;

			case isCombineType && !isDrillAndBlastCosts:
				seriesData = [
					{ name: label1.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), data: original, type: seriesType },
					{ name: label2.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), data: combineTypeSeries, type: seriesType1 },
				];
				break;

			case isDrillAndBlastCosts:
				const dataBySiteName = {};
				const siteNameAsXAxisLabels = [];
				response.forEach(entry => {
					const siteName = entry.siteName;
					if (!dataBySiteName[siteName]) {
						dataBySiteName[siteName] = [];
					}
					dataBySiteName[siteName].push(entry);
				});
				const sumOfValueOfLabel1BysiteName = this.calculateSum(dataBySiteName,label1);
				const sumOfValueOfLabel2BysiteName = this.calculateSum(dataBySiteName,label2);
				
				for(const key in dataBySiteName){
					siteNameAsXAxisLabels.push(key);
				}
				seriesData = [
					{ name: label1.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), data: sumOfValueOfLabel1BysiteName, type: seriesType },
					{ name: label2.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), data: sumOfValueOfLabel2BysiteName, type: seriesType1 },
				];
				response.forEach(item => {
					if (!(item.siteId in uniqueSites)) {
						uniqueSites[item.siteId] = item.siteName;
					}
				});	
				datasetName = siteNameAsXAxisLabels;
				break;

			default:
				seriesData = [
					{ name: yAxisLabel, data: original },
				];
				break;
		}

		const chartOptionsData =  this.chartOptions(chartType, datasetName, chartTitle, seriesData, yAxisLabel, xAxisLabel, unitAbbreviation, isSizeRange, isOversizePercent, isFinesPercent);
		
		if(isSizeRange && !isOversizePercent) {
			chartOptionsData.chart['isFragmentSizeRangeChart'] = true;
			const unit = this.user.unit('smallDistance');
			const minDiameterStr = response.map(dataset => measurement.format(dataset.minimumRocksDiameter, { unit }));
			const finesName = `Fines < ${minDiameterStr}`;
			if(this.siteId) {
				chartOptionsData['oversizeSeriesData'] = {
					data: response.map(data => data?.oversizeGoal),
					datasetIds: response.map(data => data.datasetId)
				}
			
				chartOptionsData['goalSeriesData'] = {
					color: "#F535AA",
					name: `${finesName}`,
					data: response.map(data => Number(measurement.toMaxDecimals(data.finesGoal, 2))),
					datasetIds: response.map(data => data.datasetId)
				}
			}
			else {
				chartOptionsData['goalSeriesData'] = {
					color: "#F535AA",
					name: `${finesName}`,
					data: [],
					datasetIds: []
				}
				chartOptionsData['oversizeSeriesData'] = {
					data: [],
					datasetIds: []
				}
			}
		}

		if(isCombineType && isDesignBurdenGraph) {
			chartOptionsData.chart['isDesignBurdenGraph'] = true;
			chartOptionsData['datasetId'] = response.map(data => data.datasetId);
			chartOptionsData['designBurdens'] = response.map(data => data.designBurden);
		}
		
		if (isDrillAndBlastCosts) {
			chartOptionsData['isDrillAndBlastCosts'] = isDrillAndBlastCosts;
		}
		
		if (isSizeRange && isOversizePercent) {
			chartOptionsData.chart['isOversizePercent'] = true;
		}

		if (isFinesPercent) {
			chartOptionsData.chart['isFinesPercent'] = true;
		}
		return chartOptionsData;
	}

	private generateSizeRangeSeriesData(response) {
		const resultsWithFragmentation = response.filter(res => res?.fragmentationSizeRanges?.rocksAreaPercent);
		let arrangeData = this.mergeFragmentationData(resultsWithFragmentation,this.user.unit('smallDistance'));
		return arrangeData;
	}
	
	
	private generateLabourAndHourSeriesData(response)  {
		const averagesBySite = response.reduce((acc, item) => {
			const siteId = item.siteId;
			acc[siteId] = acc[siteId] || { laborSum: 0, hoursSum: 0, count: 0 };
			acc[siteId].laborSum += item.noOfLabors;
			acc[siteId].hoursSum += item.hours;
			acc[siteId].count++;
			return acc;
	   }, {});

		const dataArray: { laborSum: number,hoursSum:number, count: number }[] = Object.values(averagesBySite);

		const noOfLabors = dataArray.map(siteData => parseInt((siteData.laborSum / siteData.count).toFixed(2)));
		const noOfHours = dataArray.map(siteData => parseInt((siteData.hoursSum / siteData.count).toFixed(2)));

		const seriesData = [
			{ name: "Average laborers", data: noOfLabors, type: 'column' },
			{ name: "Average hours", data: noOfHours, type: 'column' },
		];
		return seriesData;
	}

	private generateTruckGraphSeriesData(response) {
		let truckData = response
			.map(res => res.trucks)
			.filter(Boolean)
			.flat()
			.reduce((acc, res) => {
				if (!acc[res.code]) {
					acc[res.code] = [];
				}
				acc[res.code].push(res);
				return acc;
		}, {});

		return  this.mergeExplosiveAmount(truckData)
	}
	
	private mergeExplosiveAmount(truckData) {
		const mergedData = {};        
		Object.keys(truckData).forEach(code => {
			const mergedObject = truckData[code].reduce((acc, obj) => {
				if (acc.code === undefined) {
					acc = { ...obj };
				} else {
					acc.explosiveAmount = (parseInt(acc.explosiveAmount) + parseInt(obj.explosiveAmount)).toString();
				}
				return acc;
			}, {});
			mergedData[code] = [mergedObject];
		});
		
		return mergedData;
	}
	
	private chartOptions(chartType: string, xAxisCategories: string[], chartTitle: string, seriesData: any, yAxisLabel: string, xAxisLabel:string, unitAbbreviation, isSizeRange?, isOversizePercent?, isFinesPercent?) {
		
		const options:Highcharts.Options = {
			chart: {
				type: chartType,
				style: {
					fontFamily: 'Lato'
				}			},
			title: {
				text: `${chartTitle}`,
				align: 'left'
			},
			xAxis: {
				categories: xAxisCategories,
				title: {
					text: xAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			yAxis: {
				title: {
					text: unitAbbreviation ? `${yAxisLabel} (${unitAbbreviation})` : yAxisLabel === 'Hole Count' ? `${yAxisLabel} (no)` : isOversizePercent || isFinesPercent ? `${yAxisLabel} (%)` : `${yAxisLabel}`,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			credits: {
				enabled: false
			},
			series: seriesData
		}
		return options;
  	}

	private processTimelineDate(timeline: string) {
		const timelineRanges = { '1D': 1, '1W': 7, '1M': 30, '3M': 90, '6M': 180, '1Y': 365 };
		const daysAgo = timelineRanges[timeline];
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - daysAgo);
		return this.datePipe.transform(startDate, 'yyyy-MM-dd', 'UTC');
	}

	private mergeFragmentationData(filterResponse, unit) {
		let seriesData = [];
		const nameToIndexMap = new Map();
	
		const addDataToSeries = (name, datasetIndex, value, color = null) => {
			if (!nameToIndexMap.has(name)) {
				const entry = {
					type: 'column',
					name,
					data: Array(datasetIndex).fill(null),
					color,
				};
				entry.data.push(value);
				seriesData.push(entry);
				nameToIndexMap.set(name, seriesData.length - 1);
			} else {
				const index = nameToIndexMap.get(name);
				seriesData[index].data.push(value);
			}
		};
	
		filterResponse.forEach((dataset, datasetIndex) => {
			const minDiameterStr = measurement.format(dataset.minimumRocksDiameter, { unit });
			const finesName = `Fines < ${minDiameterStr}`;
			const finesPercent = Number(measurement.toMaxDecimals(dataset.finesPercent, 2));
			addDataToSeries(finesName, datasetIndex, finesPercent, 'magenta');
	
			if (!dataset.fragmentationSizeRanges.sizeMm.includes(Infinity) || 
				!dataset.fragmentationSizeRanges.sizeMm.includes(-Infinity)) {
				dataset.fragmentationSizeRanges.sizeMm = [-Infinity, ...dataset.fragmentationSizeRanges.sizeMm, Infinity];
			}
	
			dataset.fragmentationSizeRanges.sizeMm.forEach((size, index) => {
				if (index < dataset.fragmentationSizeRanges.sizeMm.length - 1) {
					const startStr = measurement.format(size / 1000, { unit });
					const endStr = measurement.format(dataset.fragmentationSizeRanges.sizeMm[index + 1] / 1000, { unit });
					const sizeName = `${startStr} to ${endStr}`;
					const percent = Number(measurement.toMaxDecimals(dataset.fragmentationSizeRanges.rocksAreaPercent[index], 2));
					const color = dataset.fragmentationSizeRanges.colors?.[index] || null;
					addDataToSeries(sizeName, datasetIndex, percent, color);
				}
			});
	
			seriesData.forEach((entry) => {
				if (entry.data.length < datasetIndex + 1) {
					entry.data.push(null);
				}
			});
		});
	
		const sizeSortedSeriesData = seriesData
			.filter(entry => entry.name && !entry.name.startsWith("Fines"))
			.sort((a, b) => {
				const parseStart = (name) => parseFloat(name.split(' to ')[0].replace(unit, '').trim());
				const aStart = parseStart(a.name);
				const bStart = parseStart(b.name);
				if (isNaN(aStart)) return 1; 
				if (isNaN(bStart)) return -1;
				return bStart - aStart;
			});
	
		const finesEntry = seriesData.find(entry => entry.name && entry.name.startsWith("Fines"));
		if (finesEntry) sizeSortedSeriesData.push(finesEntry);
	
		return sizeSortedSeriesData;
	}
	

	private calculateSum(data,label: string) {
		const totalSum = [];
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				
				const entries = data[key];
				const sum = entries.reduce((acc, entry) => acc + Number(entry[label]), 0);
				totalSum.push(sum);
			}
		}
		return totalSum;
	}
}